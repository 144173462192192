
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import phoneCountryVariant from '@/definitions/_general/_data/countryVariant'
import CarrierLookupDetailsTable from '@/components/pages/services/carrierLookup/shared/CarrierLookupDetailsTable.vue'
import CarrierLookupSingleBlock from '@/components/pages/services/carrierLookup/shared/CarrierLookupSingleBlock.vue'
import CarrierLookupSingleFormLine
  from '@/components/pages/services/carrierLookup/shared/CarrierLookupSingleFormLine.vue'

export default defineComponent({
  name: 'ServicesCarrierSingleLookup',
  components: { CarrierLookupSingleFormLine, CarrierLookupSingleBlock, CarrierLookupDetailsTable, TmButton },
  setup() {
    const phone = ref('')
    const country = ref(phoneCountryVariant[0])
    const visibleResults = ref(false)
    const toggleResults = (val: boolean) => {
      visibleResults.value = val

      if (val) phone.value = '(603) 555-0123'
      else phone.value = ''
    }

    return {
      phoneCountryVariant,
      phone,
      country,
      visibleResults,
      toggleResults,
    }
  },
})
