import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1cbe1a05"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "headline-18 lh-22 mb-4"
}
const _hoisted_2 = { class: "phone__container" }
const _hoisted_3 = { class: "phone__number" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_country = _resolveComponent("tm-country")!
  const _component_details_table = _resolveComponent("details-table")!
  const _component_tm_scrollbar = _resolveComponent("tm-scrollbar")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.hideTitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Lookup results"))
      : _createCommentVNode("", true),
    _createVNode(_component_tm_scrollbar, {
      "allow-scroll-x": "",
      visible: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_details_table, {
          items: _ctx.resultsItems,
          "label-size": "large",
          class: "mb-2"
        }, {
          "phone-number": _withCtx(({ value }) => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(value.phone), 1),
              _createVNode(_component_tm_status, _mergeProps(value.status, { class: "mr-3" }), null, 16),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value.actions, (item, i) => {
                return (_openBlock(), _createBlock(_component_tm_button, {
                  key: i,
                  size: "small",
                  class: "px-2",
                  onClick: item.action
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_tm_icon, {
                      name: item.icon,
                      size: "small",
                      left: ""
                    }, null, 8, ["name"]),
                    _createElementVNode("span", null, _toDisplayString(item.label), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ])
          ]),
          country: _withCtx(({ value }) => [
            _createVNode(_component_tm_country, {
              country: value,
              size: "xSmall"
            }, null, 8, ["country"])
          ]),
          "phone-type": _withCtx(({ value }) => [
            _createVNode(_component_tm_status, _normalizeProps(_guardReactiveProps(value)), null, 16)
          ]),
          _: 1
        }, 8, ["items"])
      ]),
      _: 1
    })
  ]))
}