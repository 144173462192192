
import { defineComponent } from 'vue'
import DetailsTable from '@/components/shared/templates/details/DetailsTable.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import useCarrierLookupOpenModal from '@/compositions/services/useCarrierLookupOpenModal'

export default defineComponent({
  name: 'CarrierLookupDetailsTable',
  components: { TmCountry, TmButton, TmStatus, DetailsTable, TmScrollbar },
  props: {
    hideTitle: {
      type: Boolean,
    },
  },
  setup() {
    const { openContactModal } = useCarrierLookupOpenModal()
    const resultsItems = [
      {
        slot: 'phone-number',
        label: 'Phone number (E164 format)',
        rightClass: 'py-0 d-flex align-center',
        value: {
          phone: '+372 695 8439',
          status: {
            name: 'Valid',
            color: 'green',
            outline: true,
          },
          actions: [
            { label: 'Send SMS', icon: 'email', action: () => {} },
            { label: 'Add to contacts', icon: 'tmi-person-add', action: openContactModal },
          ],
        },
      },
      { label: 'International', value: '+372 695 8439' },
      { label: 'National', value: '695 8439' },
      { label: 'RFC3966', value: 'tel:+372-695-8439' },
      { label: 'Out-of-country format from US', value: '011 372 695 8439' },
      { label: 'Timezones', value: 'Europe/Bucharest' },
      {
        label: 'Country',
        rightClass: 'py-0 d-flex align-center',
        value: {
          name: 'Estonia',
          id: 'ee',
        },
      },
      {
        label: 'Phone type',
        rightClass: 'py-0 d-flex align-center',
        value: {
          name: 'Landline',
          color: 'gray',
          outline: true,
        },
      },
      { label: 'Carrier name', value: 'RIIGI INFOKOMMUNIKATSIOONI SIHTASUTUS' },
      { label: 'Mobile country code', value: '248' },
      { label: 'Mobile network code', value: '-' },
    ]

    return {
      resultsItems,
    }
  },
})
